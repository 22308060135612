<template>
    <div>
        <AddField :addInformation="{title:'Events'}"></AddField>
    </div>
</template>
<script>
import AddField from "../Common/AddField.vue"
export default {
    components: {
    AddField,
  },
    data() {
        return {
            
        }
    },
}
</script>
<style>

</style>